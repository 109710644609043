window.EmberENV = (function(EmberENV, extra) {
  for (var key in extra) {
    EmberENV[key] = extra[key];
  }

  return EmberENV;
})(window.EmberENV || {}, {"FEATURES":{"IntegrationsRewrite":false},"EXTEND_PROTOTYPES":{"Date":false,"String":false,"Function":false,"Array":true},"BRANCH_NAME":"","_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":true,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true,"EMBER_KIT_VERSION":"6.556.1"});

var runningTests = false;


