define("ember-validators/format", ["exports", "ember-validators/utils/validation-error"], function (_exports, _validationError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateFormat;
  _exports.regularExpressions = void 0;
  var canInvoke = Ember.canInvoke;
  /**
   *  @class Format
   *  @module Validators
   */

  /**
   * @method validate
   * @param {Any} value
   * @param {Object} options
   * @param {Boolean} options.allowBlank If true, skips validation if the value is empty
   * @param {String} options.type Can be the one of the following options [`email`, `phone`, `url`]
   * @param {String} options.inverse If true, pass if the value doesn't match the given regex / type
   * @param {Regex} options.regex The regular expression to test against
   * @param {Boolean} options.allowNonTld If true, the predefined regular expression `email` allows non top-level domains
   * @param {Number} options.minTldLength The min length of the top-level domain on the predefined `email` regular expression
   * @param {Object} model
   * @param {String} attribute
   */

  var regularExpressions = {
    // eslint-disable-next-line no-useless-escape
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
    // eslint-disable-next-line no-useless-escape
    phone: /^([\+]?1\s*[-\/\.]?\s*)?(\((\d{3})\)|(\d{3}))\s*[-\/\.]?\s*(\d{3})\s*[-\/\.]?\s*(\d{4})\s*(([xX]|[eE][xX][tT]?[\.]?|extension)\s*([#*\d]+))*$/,
    // eslint-disable-next-line no-useless-escape
    url: /(?:([A-Za-z]+):)?(\/{0,3})[a-zA-Z0-9][a-zA-Z-0-9]*(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-{}]*[\w@?^=%&amp;\/~+#-{}])??/
  };
  _exports.regularExpressions = regularExpressions;

  function validateFormat(value, options, model, attribute) {
    var regex = options.regex,
        type = options.type,
        _options$inverse = options.inverse,
        inverse = _options$inverse === void 0 ? false : _options$inverse,
        allowBlank = options.allowBlank;
    (false && !(!Ember.isEmpty(Object.keys(options))) && Ember.assert("[validator:format] [".concat(attribute, "] no options were passed in"), !Ember.isEmpty(Object.keys(options))));
    var regexTest = regex;

    if (allowBlank && Ember.isEmpty(value)) {
      return true;
    }

    if (type && !regex && regularExpressions[type]) {
      regexTest = regularExpressions[type];
    }

    if (type === 'email') {
      if (regexTest === regularExpressions.email) {
        regexTest = formatEmailRegex(options);
      }

      Object.assign({}, options, {
        regex: regexTest
      });
    }

    if (!canInvoke(value, 'match') || regexTest && Ember.isEmpty(value.match(regexTest)) !== inverse) {
      return (0, _validationError.default)(type || 'invalid', value, Object.assign({}, options, {
        regex: regexTest
      }));
    }

    return true;
  }

  function formatEmailRegex(options) {
    var source = regularExpressions.email.source;
    var allowNonTld = options.allowNonTld,
        minTldLength = options.minTldLength;

    if (!Ember.isNone(minTldLength) && typeof minTldLength === 'number') {
      source = source.replace('[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$', "[a-z0-9]{".concat(minTldLength, ",}(?:[a-z0-9-]*[a-z0-9])?$"));
    }

    if (allowNonTld) {
      source = source.replace('@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)', '@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.?)');
    }

    return new RegExp(source, 'i');
  }
});