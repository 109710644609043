if (typeof FastBoot === 'undefined') { /* global Ember */

var AttrNames = [/data-.*/];

(function() {
  Ember.Component.reopen({
    init: function() {
      this._super.apply(this, arguments);
      bindDataAttributes(this);
    },
  });
})();

/**
 * Bind `data-` properties as attribute bindings. Runs on component `init()`.
 * @param {ember/Component} component
 */
function bindDataAttributes(component) {
  var dashAttrs = [];

  /** Find any `data-` properties given to component. */
  for (let attr in component) {
    if (AttrNames.map(r => r.test(attr)).filter(b => !!b).length) {
      dashAttrs.push(attr);
    }
  }

  /** Add the `data-` properties given as attribute bindings. */
  var attributeBindings = component.attributeBindings || [];
  if (!Array.isArray(attributeBindings)) {
    attributeBindings = [attributeBindings];
  }

  attributeBindings = [].concat(attributeBindings, dashAttrs);
  component.set('attributeBindings', attributeBindings);
}
 }